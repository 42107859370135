.masthead2 {
  height: 60vh;
  /* background-image: url('./../img/chairs.jpg'); */
  /* background: linear-gradient(to left, rgb(216, 150, 27), white); */
  background: rgb(255, 186, 57);
  background: linear-gradient(to left, rgb(255, 186, 57), rgb(255, 245, 103));
  /* background-color: white; */
  /* background: #007bff; */
  /* background: linear-gradient(to right, #0062e6, #33aeff); */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.tryme {
  font-size: 2.5rem;
}
.headerborder {
  margin-left: 15%;
}

.marginbottom-5 {
  margin-bottom: 5px;
}
.margintop-5 {
  margin-top: 5%;
}
.margintop-20 {
  margin-top: 20px;
}
/* For width 400px and larger: */
@media all and (min-width: 200px) and (max-width: 500px) {
  .try {
    display: none;
  }
  .headerborder {
    margin-left: 5%;
    text-align: center;
  }
}
.header2 {
  height: 100%;
}
