section.pricing {
  /* background: #007bff;
  background: linear-gradient(to right, #0062e6, #33aeff); */
  background: rgb(216, 150, 27);
  background: linear-gradient(to left, rgb(216, 150, 27), rgb(245, 247, 122));
}

.pricing .card {
  border: none;
  border-radius: 1rem;
  transition: all 0.2s;
  box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1);
}
.price-lineed {
  text-decoration: line-through red;
  font-size: 1.5em;
}

.pricing hr {
  margin: 1.5rem 0;
}

.pricing .card-title {
  margin: 0.5rem 0;
  font-size: 0.9rem;
  letter-spacing: 0.1rem;
  font-weight: bold;
}

.pricing .card-price {
  font-size: 3rem;
  margin: 0;
}

.pricing .card-price .period {
  font-size: 0.8rem;
}

.pricing ul li {
  margin-bottom: 1rem;
}

.pricing .text-muted {
  opacity: 0.7;
}

.pricing .btn {
  font-size: 80%;
  border-radius: 5rem;
  letter-spacing: 0.1rem;
  font-weight: bold;
  padding: 1rem;
  opacity: 0.7;
  transition: all 0.2s;
}

.fa-ul {
  padding-left: 0;
  margin-left: 1em !important;
  list-style-type: none;
}
.col.icon-check {
  max-width: 20% !important;
}
.fa-price {
  position: relative;
  display: table-cell;
  width: 30px !important;
  height: 30px !important;
  text-align: center;
  vertical-align: middle;
  font-size: 15px !important;
}

/* Hover Effects on Card */

@media (min-width: 992px) {
  .pricing .card:hover {
    margin-top: -0.25rem;
    margin-bottom: 0.25rem;
    box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.3);
  }
  .pricing .card:hover .btn {
    opacity: 1;
  }
}
@media (min-width: 1200px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: auto;
  }
}
