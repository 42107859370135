.user {
  padding-top: 25px;
  margin-left: 10%;
  margin-right: 10%;
}

/* WhatisThisService */
.myphone {
  padding-top: 2%;
  padding-bottom: 2%;
}
.card-block {
  font-size: 1em;
  position: relative;
  margin: 0;
  padding: 1em;
  border: none;
  box-shadow: none;
}
.card-what {
  font-size: 1em;
  overflow: hidden;
  padding: 5;
  margin-left: 15%;
  margin-right: 15%;
  margin-bottom: 5%;
  border: none;
  border-radius: 0.28571429rem;
  margin-top: 20px;
}

.carousel-indicators li {
  border-radius: 12px;
  width: 12px;
  height: 12px;
  background-color: #404040;
}
.carousel-indicators li {
  border-radius: 12px;
  width: 12px;
  height: 12px;
  background-color: #404040;
}
.carousel-indicators .active {
  background-color: white;
  max-width: 12px;
  margin: 0 3px;
  height: 12px;
}

.btn {
  margin-top: auto;
}
/* ennnndddd */
.pro {
  padding-top: 25px;
}

ul > li > div.active {
  color: #d98f06;
}
ul > li > div.active > div > svg {
  fill: #d98f06;
}

.icon > svg {
  padding-top: 5px;
  width: 3em;
  height: 3em;
  fill: black;
  display: block;
  margin: auto;
}
#usertext {
  cursor: pointer;
}
#protext {
  cursor: pointer;
}
.card-img-top {
  flex-shrink: 0;
  width: 50% !important;
}
.nav-fill {
  margin-top: 2%;
  margin-bottom: 2% !important;
  margin-left: 5%;
  margin-right: 5%;
}
